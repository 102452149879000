import React from 'react';
import { Helmet } from 'react-helmet';
import { Typography } from 'amphitheatre';

import * as Styled from '../pages-styles/shared.styled';
import {
  BackgroundWrapper,
  BLCircle,
  TLCircle,
  BRBigCircle,
  BRSmallCircle,
} from '../components/Background';
import Layout from '../components/Layout';

import ImageNoCaption from '../components/ImageNoCaption';

import image1 from '../images/facilities/img-1.jpeg';
import image2 from '../images/facilities/img-2.jpeg';
import image3 from '../images/facilities/img-3.jpeg';
import image4 from '../images/facilities/img-4.jpeg';
import image5 from '../images/facilities/img-5.jpeg';
import image6 from '../images/facilities/img-6.jpeg';
import image7 from '../images/facilities/img-7.jpeg';
import image8 from '../images/facilities/img-8.jpeg';
import image9 from '../images/facilities/img-9.jpeg';
import image10 from '../images/facilities/img-10.jpeg';
import image11 from '../images/facilities/img-11.jpeg';

const { Header, Paragraph } = Typography;
const Facilities: React.FunctionComponent = () => {
  return (
    <Layout>
      <Helmet>
        <title>Wolfpack - Facilities</title>
      </Helmet>
      <BackgroundWrapper>
        <BLCircle />
        <TLCircle />
        <BRBigCircle />
        <BRSmallCircle />
      </BackgroundWrapper>
      <Styled.Container>
        <Header className="page-header">OUR FACILITIES</Header>
        <Styled.InnerContainer>
          <Paragraph style={{ marginBottom: '2.5rem', lineHeight: '1.5rem' }}>
            The Wolfpack Space Hub is located in Waterloo, in the heart of
            Sydney. Wolfpack has a 260m<sup>2</sup> space outfitted with open
            desks, a well-equipped laboratory, an event area, two meeting rooms
            and fantastic coffee spots right outside the door. Get your space
            and robotics manufacturing done in the morning, break for coffee,
            make use of our meeting rooms and host an event that afternoon in
            our space with supplied AV equipment.
          </Paragraph>
          <Styled.Columns style={{ justifyContent: 'space-evenly' }}>
            <div style={{ flexBasis: '45%' }}>
              <ImageNoCaption src={image1} />
              <ImageNoCaption src={image3} />
            </div>
            <div style={{ flexBasis: '45%' }}>
              <ImageNoCaption src={image2} />
              <ImageNoCaption src={image4} />
            </div>
          </Styled.Columns>
        </Styled.InnerContainer>
        <Styled.InnerContainer>
          <Styled.Columns
            style={{ justifyContent: 'space-evenly', alignItems: 'center' }}
          >
            <div style={{ flexBasis: '30%' }}>
              <ImageNoCaption src={image5} />
            </div>
            <div style={{ flexBasis: '30%' }}>
              <ImageNoCaption src={image6} />
            </div>
            <div style={{ flexBasis: '30%' }}>
              <Header level={3} style={{ textAlign: 'center' }}>
                Become a Wolfpack Space Hub Resident
              </Header>
              <Paragraph style={{ lineHeight: '1.5rem', textAlign: 'center' }}>
                We offer premium services priced per desk for startups,
                including two months free residency as soon as you decide to
                join us.
              </Paragraph>
              <Paragraph style={{ lineHeight: '1.5rem', textAlign: 'center' }}>
                If you are a startup looking to base your company in our
                facility, please contact us: 
                <Styled.EmailLink href="mailto:info@wolfpackspacehub.com">
                  info@wolfpackspacehub.com
                </Styled.EmailLink>
              </Paragraph>
            </div>
          </Styled.Columns>
        </Styled.InnerContainer>
        <Styled.InnerContainer>
          <Styled.Columns style={{ justifyContent: 'space-evenly' }}>
            <div style={{ flexBasis: '50%' }}>
              <Header level={3} style={{ textAlign: 'center' }}>
                Book our Laboratory
              </Header>
              <Paragraph
                style={{
                  marginBottom: '2.5rem',
                  lineHeight: '1.5rem',
                  textAlign: 'center',
                }}
              >
                Our laboratory includes equipment for:
              </Paragraph>
            </div>
            <div style={{ flexBasis: '40%' }}>
              <ImageNoCaption src={image7} />
            </div>
          </Styled.Columns>
          <Styled.Columns style={{ justifyContent: 'space-evenly' }}>
            <div style={{ flexBasis: '40%' }}>
              <ImageNoCaption src={image8} />
            </div>
            <div style={{ flexBasis: '50%' }}>
              <Paragraph
                style={{ marginBottom: '2.5rem', lineHeight: '1.5rem' }}
              >
                <ul>
                  <li>
                    Metal machining and manufacturing with our Tormach CNC
                    machine, rotary fixed belt sander, dremels, rotary cutters,
                    angle grinders, vices and more. 
                  </li>
                  <li>Additive manufacturing using 3D printers.</li>
                  <li>
                    Specialised environments for hardware assembly such as
                    clean-benches and vacuum chambers.
                  </li>
                  <li>
                    Electrical testing and manufacturing including heat guns,
                    drills and accessories, soldering stations and more.
                  </li>
                </ul>
              </Paragraph>
            </div>
          </Styled.Columns>
          <Styled.Columns style={{ justifyContent: 'space-evenly' }}>
            <div style={{ flexBasis: '50%' }}>
              <Paragraph style={{ textAlign: 'center' }}>
                We offer work packages, per day, or per month priced for
                individuals or startups to SMEs.
                <br />
                <br />
                Contact us to discuss your needs and how we can help you: 
                <Styled.EmailLink href="mailto:info@wolfpackspacehub.com">
                  info@wolfpackspacehub.com
                </Styled.EmailLink>
              </Paragraph>
            </div>
            <div style={{ flexBasis: '40%' }}>
              <ImageNoCaption src={image9} />
            </div>
          </Styled.Columns>
        </Styled.InnerContainer>
        <Styled.InnerContainer>
          <Header level={3}>Book your next event with us</Header>
          <Paragraph style={{ marginBottom: '2.5rem', lineHeight: '1.5rem' }}>
            Our venue includes an event space equipped with an audio visual
            system that is suited for speaking, presenting and panel
            discussions. We are partnered with hospitality and catering
            suppliers to assist you in a seamless event. 
            <br />
            <br />
            <ImageNoCaption altText="" src={image10} />
            <ImageNoCaption altText="" src={image11} />
            Our standing capacity is 80-100 people.
            <br />
            <br />
            Contact us to discuss your event: 
            <Styled.EmailLink href="mailto:info@wolfpackspacehub.com">
              info@wolfpackspacehub.com
            </Styled.EmailLink>
          </Paragraph>
        </Styled.InnerContainer>
      </Styled.Container>
    </Layout>
  );
};

export default Facilities;
